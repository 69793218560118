import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Heading from '../../../components/ui/Heading';
import Text from '../../../components/ui/Text';
import { Col, Container, Row } from '../../../components/ui/Wrapper';
import { SectionWrap } from './about-us-methodology.style';

const AboutUsMethodology = ({ headingStyle, textStyle }) => {
  const methodologyData = useStaticQuery(graphql`
    query methodologyQuery {
      aboutUsJson(id: { eq: "methodology-content" }) {
        title
        subtitle
      }
    }
  `);
  debugger;
  const { title, subtitle } = methodologyData.aboutUsJson;
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col lg={12}>
            {title && <Heading {...headingStyle}>{title}</Heading>}
            {subtitle && <Text {...textStyle}>{subtitle}</Text>}
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

AboutUsMethodology.propTypes = {
  headingStyle: PropTypes.object,
  textStyle: PropTypes.object,
};

AboutUsMethodology.defaultProps = {
  headingStyle: {
    as: 'h3',
    mb: '20px',
    child: {
      color: 'primary',
    },
  },
  textStyle: {
    maxWidth: '760px',
    m: 'auto',
  },
};

export default AboutUsMethodology;
