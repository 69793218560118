import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Timeline from '../../../components/ui/timeline';
import { Col, Container, Row } from '../../../components/ui/Wrapper';
import { SectionWrap } from './style';

const TimelineArea = () => {
  const timelineDataQuery = useStaticQuery(graphql`
    query TimelineQuery {
      allTimelineJson {
        edges {
          node {
            year
            title
            desc
            image {
              childImageSharp {
                fluid(maxWidth: 500, maxHeight: 350, quality: 100, srcSetBreakpoints: 6) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
        }
      }
    }
  `);

  const content = timelineDataQuery.allTimelineJson.edges.map(({ node }) => ({ ...node }));

  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col lg={12}>
            <Timeline items={content} />
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default TimelineArea;
