import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { IoLogoWhatsapp } from 'react-icons/io';
import { MdPhone } from 'react-icons/md';
import BoxIcon from '../../components/box-icon/layout-four';
import Image from '../../components/Image';
import Anchor from '../../components/ui/Anchor';
import Ratings from '../../components/ui/ratings';
import { Box, Col, Container, Row } from '../../components/ui/Wrapper';
import { ContactInfoBox, ImageBox, SectionWrap } from './about-contact-area.style';

const AboutContactArea = ({ boxIconStyle, ratingStyle }) => {
  const contactData = useStaticQuery(graphql`
    query AboutContactQuery {
      info: site {
        siteMetadata {
          social {
            whatsapp
          }
          contact {
            whatsapp
            phone
            rating
          }
        }
      }
      bgImage: file(relativePath: { eq: "images/bg/camouflaged.png" }) {
        childImageSharp {
          fluid(maxHeight: 520, maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      contactImg: file(relativePath: { eq: "images/contact-image.png" }) {
        childImageSharp {
          fluid(maxHeight: 572, maxWidth: 587, quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
            presentationWidth
            presentationHeight
          }
        }
      }
    }
  `);
  const { phone, whatsapp: whatsappNumber, rating } = contactData.info.siteMetadata.contact;
  const { whatsapp } = contactData.info.siteMetadata.social;

  const bgImage = contactData.bgImage.childImageSharp.fluid;
  const contactImage = contactData.contactImg.childImageSharp.fluid;

  return (
    <SectionWrap fluid={bgImage}>
      <Container>
        <Row alignitems="center">
          <Col lg={6}>
            <ImageBox>
              <Image fluid={contactImage} alt="Contact Us" />
            </ImageBox>
          </Col>
          <Col lg={5} ml="auto">
            <ContactInfoBox>
              <Box textalign="center">
                <Ratings {...ratingStyle} rating={+rating} />
              </Box>
              <Box>
                <Anchor display="block" path={`tel:${phone}`}>
                  <BoxIcon
                    {...boxIconStyle}
                    icon={<MdPhone />}
                    heading="Clique aqui e entre em contato conosco"
                    title={phone}
                  />
                </Anchor>
                <Anchor display="block" path={whatsapp}>
                  <BoxIcon
                    {...boxIconStyle}
                    icon={<IoLogoWhatsapp />}
                    heading="Clique aqui e envie-nos uma mensagem"
                    title={whatsappNumber}
                  />{' '}
                </Anchor>
              </Box>
            </ContactInfoBox>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

AboutContactArea.propTypes = {
  boxIconStyle: PropTypes.object,
  ratingStyle: PropTypes.object,
};

AboutContactArea.defaultProps = {
  boxIconStyle: {
    wrapperStyle: {
      alignitems: 'center',
      mt: '55px',
    },
    iconStyle: {
      fontSize: '40px',
      mr: '25px',
      fontweight: 300,
    },
    titleStyle: {
      fontSize: '34px',
      mb: '0',
      fontweight: 700,
      lineHeight: 1.17,
      responsive: {
        large: {
          fontSize: '24px',
        },
      },
    },
  },
  ratingStyle: {
    mt: '10px',
    mb: '10px',
  },
};

export default AboutContactArea;
