/* eslint-disable react/jsx-filename-extension */
import PropTypes from 'prop-types';
import React from 'react';
import WhoWeAre from '../containers/WhoWeArePage';

const WhoWeArePage = ({ pageContext, location }) => <WhoWeAre pageContext={pageContext} location={location} />;

WhoWeArePage.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default WhoWeArePage;
