import PropTypes from 'prop-types';
import React from 'react';
import PageHeader from '../../components/pageheader';
import SEO from '../../components/seo';
import AboutContactArea from '../about-contact-area';
import AboutUsMethodology from '../about-us/about-us-methodology';
import TimelineArea from '../about-us/timeline';
import ApprovedStudents from '../ApprovedStudents';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import TestimonialSection from '../testimonial-area';

const WhoWeArePage = ({ pageContext, location }) => {
  return (
    <>
      <SEO title="Cursos Preparatórios para Concursos Públicos e Militares - Garra" location={location} />
      <Header />
      <PageHeader pageContext={pageContext} location={location} title="Sobre o GARRA" />
      <AboutUsMethodology />
      <TimelineArea />
      <TestimonialSection />
      <ApprovedStudents />
      <AboutContactArea />
      <Footer />
    </>
  );
};

WhoWeArePage.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default WhoWeArePage;
